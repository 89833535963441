import { createTheme } from '@mui/material/styles';

//#009BCE
export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#009BCE',
        // main: '#ff8d29',
      },
      secondary: {
        main: '#333333',
      },
      neutral: {
        dark: '#535252',
        main: '#cfcdcd',
        light: '#f5f5f5',
      },
      warning: {
        main: '#d6001c',
      },
      text: {
        gradient: 'linear-gradient(140deg, #009BCE 0%, #ccf9ff 20%, #55d0ff 80%, #00acdf 120%)',
      }
    },
    spacing: 20,
    typography: {
      fontFamily: ['Roboto', 'Open Sans', 'PT Sans', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        fontFamily: ['Roboto', 'Open Sans', 'PT Sans', 'sans-serif'].join(','),
        fontSize: 48,
      },
      h2: {
        fontFamily: ['Roboto', 'Open Sans', 'PT Sans', 'sans-serif'].join(','),
        fontSize: 36,
      },
      h3: {
        fontFamily: ['Roboto', 'Open Sans', 'PT Sans', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h4: {
        fontFamily: ['Roboto', 'Open Sans', 'PT Sans', 'sans-serif'].join(','),
        fontSize: 16,
      },
    },
  });