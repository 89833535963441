import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import stageReducer from './stageSlice';
import readerReducer from './readerSlice';
import cartReducer from './cartSlice';
import authReducer from './authSlice';
import errorReducer from './errorSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['stage'], 
};

const rootReducer = combineReducers({
  stage: stageReducer,
  reader: readerReducer,
  cart: cartReducer,
  auth: authReducer,
  error: errorReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});


export const persistor = persistStore(store);

export default store;
