// ExcelsiorBackground.js
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { gsap } from "gsap";
import { HOME, LIBRARY, DETAILS, PURCHASING, READING } from './store/stageSlice'

export function ExcelsiorBackground({children}) {
  const mainStage = useSelector(state => state.stage.mainStage); 
  const bgRef = useRef(null); 

  useEffect(() => {
    let color;
    switch(mainStage) {
      case HOME:
        case DETAILS:
      case LIBRARY:
      case PURCHASING:
      case READING:
        color = 'linear-gradient(to bottom, #0123401F, #0123402E)';
        break;
      default:
        color = 'linear-gradient(to bottom, #0123401F, #0123402E)';
        break;
    }
    gsap.to(bgRef.current, {
    background: color,
      duration: 1, 
    });
  }, [mainStage]);

  return (
    <div ref={bgRef} style={{width: '100vw', height: '100vh'}}>
      {children}
    </div>
  );
}
