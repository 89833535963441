import { useSelector } from 'react-redux';
import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as Splash } from './svg/loader.svg';
import { ReactComponent as Masklogo } from './svg/mask.svg';
import { Box } from '@mui/material';
import './Excelsior.css';
import { LOADING, UPLOADING } from './store/stageSlice';

function ExcelsiorOverlayFunc() {
  const stageState = useSelector(state => state.stage); // Get the entire stage state

  switch (stageState.overlayStage) { // Check the overlayStage instead
    case LOADING:
      return !stageState.isMaskLoader  ? (
        <div style={{
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          zIndex: 9999,
          pointerEvents: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
        }}>
        <div className="excel_fade_in">
          <div className="excel_center_vert">
          <div className="excel_svg_line">
            <Splash />
            <div className='excel_svg_line_text'>This app is meant for faster connections. Loads may take a while.</div>
            </div>

          </div>
        </div>
        </div>
      ) : (
        <div style={{
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          zIndex: 9999
        }}>
          <div className="working_indicator_bottom">
            <div className="excel_fade_in">
              <Box sx={{ width: '100%', height: '100%' }}>
                <LinearProgress />
              </Box>
              <Masklogo className="excel_mask_logo"/>
              
            </div>
          </div>
        </div>
      );
    
    case UPLOADING:
      return (
        <div style={{
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          zIndex: 9999,
          pointerEvents: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black background with 50% transparency
        }}>
        <div className="excel_fade_in">
          <div className="excel_center_vert">
            <Splash className="excel_svg_line" />
          </div>
        </div>
        </div>
      );
    
    default:
      return null;
  }
}

export const ExcelsiorOverlay = React.memo(ExcelsiorOverlayFunc);
