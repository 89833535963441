import React, { Suspense } from 'react';
import './App.css';
import {Excelsior} from './excelsior/Excelsior' 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from './Theme';


const FAQPage = React.lazy(() => import('./FAQ/FAQPage'));
const Terms = React.lazy(() => import('./Terms'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const NotFound = React.lazy(() => import('./global/NotFound'));

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Put any header content here */}
      </header>
      <main className="App-content">

      <ThemeProvider theme={darkTheme}>
        <Router>
          <Routes>
            <Route path="/faq" element={<Suspense fallback={<div>Loading...</div>}><FAQPage /></Suspense>} />
            <Route path="/terms" element={<Suspense fallback={<div>Loading...</div>}><Terms /></Suspense>} />
            <Route path="/privacypolicy" element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>} />
            <Route path="/" element={<Excelsior />} />
            <Route path="*" element={<Suspense fallback={<div>Loading...</div>}><NotFound /></Suspense>} />
          </Routes>
        </Router>
        </ThemeProvider>

      </main>
    </div>
  );
}

export default App;