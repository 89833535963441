import { createSlice } from '@reduxjs/toolkit'; 


export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    address: null,
  },
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
  },
});

export const { setAddress } = authSlice.actions;

export default authSlice.reducer;