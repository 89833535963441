import React, { Component } from 'react';

class ExcelsiorErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error here or send it to a logging service
    console.error(">>>> ", error, errorInfo);
    this.setState({
      error: ExcelsiorErrorBoundary.findErrorMessageInObject(error),
      errorInfo: ExcelsiorErrorBoundary.findErrorMessageInObject(errorInfo),
    });
    
  }

  

  // static findErrorMessageInObject(obj, depth) {
  //   if (depth === 0) {
  //     return null;
  //   }
  
  //   for (let key in obj) {
  //     if ((key === 'message' || key === 'details' || key === 'data') && typeof obj[key] === 'string' && !ExcelsiorErrorBoundary.isHTML(obj[key]) && !ExcelsiorErrorBoundary.isHTMLSecondCheck(obj[key])) {
  //       return obj[key];
  //     } else if (typeof obj[key] === 'object') {
  //       let message = ExcelsiorErrorBoundary.findErrorMessageInObject(obj[key], depth - 1);
  //       if (message) {
  //         if (!ExcelsiorErrorBoundary.isHTML(message) && !ExcelsiorErrorBoundary.isHTMLSecondCheck(message)) {
  //           return null;
  //         } else {
  //           return message;
  //         }
  //       }
  //     }
  //   }
  //   return null;
  // }

  static findErrorMessageInObject(obj, depth = 8) {
    if (depth === 0 || obj === null || typeof obj === 'undefined') {
        return null;
    }

    let prioritizedMessages = [];
    let secondaryMessages = [];

    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            let isHTMLContent = ExcelsiorErrorBoundary.isHTML(obj[key]) || ExcelsiorErrorBoundary.isHTMLSecondCheck(obj[key]);
            if (["message", "details", "data"].includes(key) && !isHTMLContent) {
                prioritizedMessages.push(obj[key]);
            } else if (!isHTMLContent) {
                secondaryMessages.push(obj[key]);
            }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            let message = ExcelsiorErrorBoundary.findErrorMessageInObject(obj[key], depth - 1);
            if (message) {
                secondaryMessages.push(message);
            }
        }
    }

    if (prioritizedMessages.length > 0) {
        return prioritizedMessages[0];  // Return the first prioritized message
    } else if (secondaryMessages.length > 0) {
        return secondaryMessages[0];    // If no prioritized message, return the first secondary message
    } else {
        return null;
    }
}


  
  // Helper function to check if a string is HTML
 // Helper function to check if a string is HTML
static isHTML(str) {
  const htmlRegex = /<(?:!DOCTYPE\s+)?[a-z][\s\S]*>/i;
  return htmlRegex.test(str) || str.trim().startsWith('<html') || str.trim().startsWith('<!DOCTYPE');
}
static isHTMLSecondCheck(str) {
  try {
      const doc = new DOMParser().parseFromString(str, 'text/html');
      return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); // Check if there's any element node
  } catch (e) {
      return false;
  }
}
  

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary>Click for error details</summary>
            {this?.state?.error && this?.state?.error?.toString()}
            <br />
            {this?.state?.errorInfo?.componentStack || this?.state?.errorInfo}
          </details>
        </div>
      );
    }

    // If there is an error in the state, throw it to trigger the error boundary
    if (this.state.error) {
      throw this.state.error;
    }

    return this.props.children; 
  }
}

export default ExcelsiorErrorBoundary;