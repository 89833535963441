
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

export const HOME = 'HOME'
export const LOADING = 'LOADING' 
export const PURCHASING = 'PURCHASING'
export const UPLOADING = 'UPLOADING' 
export const READING = 'READING'
export const LIBRARY = 'LIBRARY'
export const DETAILS = 'DETAILS'

const initialState = {
  mainStage: HOME,
  overlayStage: null,
  selectedItem: null,
  isMaskLoader: false,
};


const stageSlice = createSlice({
  name: "stage",
  initialState,
  reducers: {
    setHome: (state) => {
      state.overlayStage = null
      state.mainStage = HOME
    },
    setLoading: (state, action) => {
      state.isMaskLoader = action.payload !== undefined ? action.payload : false
      state.overlayStage = LOADING  
    },
    setPurchasing: (state) => {
      state.overlayStage = null
      state.mainStage = PURCHASING;
    },
    setUploading: (state, action) => {
      state.overlayStage = UPLOADING
    },
    setReading: (state) => {
      state.overlayStage = null
      state.mainStage = READING
    },
    setLibrary: (state) => {
      state.overlayStage = null
      state.mainStage = LIBRARY
    },
    setDetails: (state, action) => {
      state.overlayStage = null
      state.selectedItem = action.payload !== undefined ? action.payload : null
      state.mainStage = DETAILS
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload !== undefined ? action.payload : null
    }
  },
});

const persistConfig = {
  key: 'stage',
  storage,
};

export const {
  setHome,
  setLoading,
  setPurchasing,
  setUploading,
  setReading,
  setLibrary,
  setDetails
} = stageSlice.actions

export default persistReducer(persistConfig, stageSlice.reducer);
