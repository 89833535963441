import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

const modes = ["classic", "focus", "build"];

const DEFAULT_ZOOM_MAX = 2
const DEFAULT_TRANS_SPEED = .5
//todo: Will need to come up with a better solution to read whether something is manga or not
const DEFAULT_MANGA_SETTING = false
const DEFAULT_FAB_MENU_SETTING = false
const DEFAULT_FOCUS_ANIM_SETTING = true

const initialState = {
  working: true,
  animating: false,
  doNext: ["",0],
  loadObject: null,
  end:false,
  numPages: 0,
  mode: "classic",
  lockMode: false,
  zoomed: false,
  zoomedPosition: { x: 0, y: 0 },
  currentIssueID: 0,
  currentIssueName: "",
  currentIssueURI: "",
  currentPage: 1,
  currentPanel: 1,
  currentPages: [0,1],
  settingsMenuOpen: false,
  issueObject: null,
  admin: null,
  buildBounding: null,
  buildGenerate: false,
  zoomMax: DEFAULT_ZOOM_MAX,
  transitionSpeed: DEFAULT_TRANS_SPEED,
  mangaSwitchValue: DEFAULT_MANGA_SETTING,
  fabMenuSwitchValue: DEFAULT_FAB_MENU_SETTING,
  focusAnimationSwitchValue: DEFAULT_FOCUS_ANIM_SETTING,
  //DEFAULTS
  defaultZoomMax: DEFAULT_ZOOM_MAX,
  defaultTransitionSpeed: DEFAULT_TRANS_SPEED,
  defaultMangaSwitchValue: DEFAULT_MANGA_SETTING,
  defaultFabMenuSwitchValue: DEFAULT_FAB_MENU_SETTING,
  defaultFocusAnimationSwitchValue: DEFAULT_FOCUS_ANIM_SETTING
};


const readerSlice = createSlice({
  name: "issueDataContext",
  initialState,
  reducers: {

    setWorking: (state, action) => {
      state.working = action.payload;
    },
    setAnimating: (state, action) => {
      state.animating = action.payload;
    },
    setDoNext: (state, action) => {
      state.doNext = action.payload;
    },
    setLoadObject: (state, action) => {
      state.loadObject = action.payload;
    },
    setEnd: (state, action) => {
      state.end = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setMode: (state, action) => {
      const val = action.payload;
      for(let i in modes){
        if(modes[i] === val) {
          state.mode = modes[i];
          break;
        }
      }
    },
    setLockMode: (state, action) => {
      state.lockMode = action.payload;
    },
    setZoomed: (state, action) => {
      state.zoomed = action.payload;
    },
    setZoomedPosition: (state, action) => {
      state.zoomedPosition = action.payload;
    },
    setCurrentIssueID: (state, action) => {
      if(action.payload !== state.currentIssueID || action.payload === "Upload"){
        state.currentPage = 1;
        state.currentPages = [0,1];
        state.mode = modes[0];
        state.zoomed = false;
        state.refresh = true;
      }else{
        state.refresh = false;
      }
      state.currentIssueID = action.payload;
    },
    setCurrentIssueName: (state, action) => {
      state.currentIssueName = action.payload;
    },
    setCurrentIssueURI: (state, action) => {
      state.currentIssueURI = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setCurrentPanel: (state, action) => {
      state.currentPanel = action.payload;
    },
    setCurrentPages: (state, action) => {
      state.currentPages = action.payload;
    },
    setSettingsMenuOpen: (state, action) => {
      state.settingsMenuOpen = action.payload;
    },
    setIssueObject: (state, action) => {
      state.issueObject = action.payload;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    setBuildBounding: (state, action) => {
      state.buildBounding = action.payload;
    },
    setBuildGenerate: (state, action) => {
      state.buildGenerate = action.payload;
    },
    setZoomMax: (state, action) => {
      state.zoomMax = action.payload;
    },
    setTransitionSpeed: (state, action) => {
      state.transitionSpeed = action.payload;
    },
    setMangaSwitchValue: (state, action) => {
      state.mangaSwitchValue = action.payload;
    },
    setFabMenuSwitchValue: (state, action) => {
      state.fabMenuSwitchValue = action.payload;
    },
    setFocusAnimationSwitchValue: (state, action) => {
      state.focusAnimationSwitchValue = action.payload;
    },
  },
});

const persistConfig = {
    key: 'reader',
    storage,
    whitelist: ['mode', 'lockMode', 'zoomed', 'setZoomedPosition', 'currentIssueID', 'currentPage', 'currentPages', 'zoomMax', 'transitionSpeed', 'mangaSwitchValue', 'fabMenuSwitchValue', 'focusAnimationSwitchValue'] // Only persist these state keys
  };

export const {
  setWorking,
  setAnimating,
  setDoNext,
  setLoadObject,
  setEnd,
  setNumPages,
  setMode,
  setLockMode,
  setZoomed,
  setZoomedPosition,
  setCurrentIssueID,
  setCurrentIssueName,
  setCurrentIssueURI,
  setCurrentPage,
  setCurrentPanel,
  setCurrentPages,
  setSettingsMenuOpen,
  setIssueObject,
  setAdmin,
  setBuildBounding,
  setBuildGenerate,
  setZoomMax,
  setTransitionSpeed,
  setMangaSwitchValue,
  setFabMenuSwitchValue,
  setFocusAnimationSwitchValue,
} = readerSlice.actions;

export default persistReducer(persistConfig, readerSlice.reducer);