// errorSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
    NotificationManager,
  } from "react-notifications";
  import 'react-notifications/lib/notifications.css';

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: null,
  },
  reducers: {
    setError: (state, action) => {
      if (typeof action.payload === 'string') {
        state.message = action.payload;
      } else {
        let error = action.payload; 
        let message = '';
    
        if (error?.reason) {
          message = error.reason;
        } else if (error?.response) {
          message = findErrorMessageInObject(error.response, 3);
        } else if (error?.request) {
          message = findErrorMessageInObject(error.request, 3);
        } else if (error?.message) {
          message = error.message;
        }
    
        state.message = message || 'An unknown error occurred';
      }
    
      NotificationManager.error(state.message, null, 10000);
    },
    clearError: (state) => {
      state.message = null;
    },
  },
});

// Helper function to recursively search for error messages in an object
function findErrorMessageInObject(obj, depth) {
  if (depth === 0) {
    return null;
  }

  for (let key in obj) {
    if ((key === 'message' || key === 'details' || key === 'data') && typeof obj[key] === 'string' && !isHTML(obj[key])) {
      return obj[key];
    } else if (typeof obj[key] === 'object') {
      let message = findErrorMessageInObject(obj[key], depth - 1);
      if (message) {
        return message;
      }
    }
  }
  return null;
}

// Helper function to check if a string is HTML
// Helper function to check if a string is HTML
function isHTML(str) {
  const htmlRegex = /<(?:!DOCTYPE\s+)?[a-z][\s\S]*>/i;
  return htmlRegex.test(str) || str.trim().startsWith('<html') || str.trim().startsWith('<!DOCTYPE');
}


export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;