import { createSlice } from '@reduxjs/toolkit';
import {
  NotificationManager,
} from "react-notifications";
import 'react-notifications/lib/notifications.css';


const initialState = {
  cart: [],
  isCartOpen: false,
};

const MAX_ITEMS_IN_CART = 5;
const MAX_COUNT_IN_CART = 300;
export const MAX_COUNT_PER_ITEM = 100;

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const currentTotalCount = state.cart.reduce((total, item) => total + item.count, 0);
      const availableSpace = MAX_COUNT_IN_CART - currentTotalCount;
  
      if (availableSpace <= 0) {
        NotificationManager.error("You've reached the maximum total quantity of items for your cart.");
        return;
      }
  
      const itemToAdd = action.payload.item;
      const existingItemIndex = state.cart.findIndex((item) => item.id === itemToAdd.id);
  
      if (existingItemIndex > -1) {
        const currentItem = state.cart[existingItemIndex];
        const availableSpacePerItem = MAX_COUNT_PER_ITEM - currentItem.count;
        const addableCount = Math.min(availableSpace, availableSpacePerItem, itemToAdd.count);
  
        if (addableCount < itemToAdd.count) {
          NotificationManager.error("You can only add " + addableCount + " more units of this book.");
        }
  
        currentItem.count += addableCount;
      } else {
        // Check if adding this item would exceed the MAX_ITEMS_IN_CART limit
        if (state.cart.length >= MAX_ITEMS_IN_CART) {
          NotificationManager.error("You've reached the maximum number of unique books you can add to your cart at a time. Don't worry, you can checkout and add more later.", null, 7500);
          return;
        }

        if (itemToAdd.count > MAX_COUNT_PER_ITEM) {
          NotificationManager.error("You've tried to add more than the allowed quantity for this book. We've adjusted the quantity to the maximum allowed.");
        }
  
        itemToAdd.count = Math.min(itemToAdd.count, MAX_COUNT_PER_ITEM);
        state.cart.push(itemToAdd);
      }
  },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item.id !== action.payload.id);
    },
    increaseCount: (state, action) => {
      const currentTotalCount = state.cart.reduce((total, item) => total + item.count, 0);

      if (currentTotalCount >= MAX_COUNT_IN_CART) {
        NotificationManager.error("You've reached the maximum total quantity of items for your cart.");
        return;
      }

      const itemToIncrease = state.cart.find((item) => item.id === action.payload.id);

      if (itemToIncrease && itemToIncrease.count < MAX_COUNT_PER_ITEM) {
        itemToIncrease.count++;
      } else {
        NotificationManager.error("You've reached the maximum quantity for this book.");
      }
    },
  decreaseCount: (state, action) => {
      state.cart = state.cart.map((item) => {
          if(item.id === action.payload.id && item.count > 1){
              item.count--;
          }
          return item;
      })
  },
    setIsCartOpen: (state, action) => {
      state.isCartOpen = action.payload;
    },
    clearCart: (state) => {
      state.cart = [];
    },
    refreshCart: (state, action) => {
      // Create a map of the current cart items by their ID for quick lookup
      // const currentItemsById = state.cart.reduce((acc, item) => {
      //   acc[item.id] = item;
      //   return acc;
      // }, {});
    
      // Assume action.payload is an array of fetched items
      const fetchedItemsById = action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    
      // Filter out any items that were deleted while fetching
      const filteredCurrentItems = state.cart.filter(item => fetchedItemsById.hasOwnProperty(item.id));
    
      // Merge current items with fetched items, preserving current item counts and any other properties
      // Only include items that are still in the cart
      const updatedCartItems = filteredCurrentItems.map(currentItem => {
        // This ensures we're updating the item with fetched data while preserving the current item's properties like count
        return { ...fetchedItemsById[currentItem.id], ...currentItem };
      });
    
      // Update the cart with the merged items
      state.cart = updatedCartItems;
    },
    
  },
  
});

export const {
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  setIsCartOpen,
  clearCart,
  refreshCart,
} = cartSlice.actions;

export default cartSlice.reducer;


export const selectTotalCount = (state) => {
  return state.cart.cart.reduce((total, item) => total + item.count, 0);
}
