import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/ExeclsiorStore';
import { ExcelsiorMain } from './ExcelsiorMain';
import {IssueDataProvider} from './store/ExcelsiorDataContext'



import {AnimatePresence} from 'framer-motion'
import { ExcelsiorBackground } from './ExcelsiorBackground';


import {
  NotificationContainer
} from "react-notifications";





export function Excelsior (){



return (
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

        <IssueDataProvider>
          <AnimatePresence>


          
          <ExcelsiorBackground>
            <ExcelsiorMain />
            </ExcelsiorBackground>


          </AnimatePresence>
        </IssueDataProvider>


      </PersistGate>
    </Provider>
    <NotificationContainer/>
  </>
  
);
}

