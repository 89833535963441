import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { ExcelsiorOverlay } from './ExcelsiorOverlay';
import { HOME, LIBRARY, DETAILS, PURCHASING, UPLOADING, READING, LOADING } from './store/stageSlice'
import ExcelsiorErrorBoundary from './ExcelsiorErrorBoundary'
import {motion} from 'framer-motion'
import './Excelsior.css'

const ExcelsiorReader = React.lazy(() => import('./ExcelsiorReader').then(module => ({ default: module.ExcelsiorReader })));


export function ExcelsiorMain() {
  
    const stageState = useSelector(state => state.stage);

    const content = (

        <motion.div 
        key={stageState.mainStage}       
         initial={stageState.mainStage === HOME ? {opacity:0} : {opacity: 0, scale: 0.9, y:"10%"}}
        animate={{opacity: 1, scale: 1, y:0}}
        >
          {getComponentBasedOnState(stageState.mainStage)}
        </motion.div>

    );


    const overlay = (stageState.overlayStage === LOADING || stageState.overlayStage === UPLOADING) ? <ExcelsiorOverlay isMinimal={stageState.isMinimal} /> : null;



    return (
      <ExcelsiorErrorBoundary>
      <Suspense fallback={overlay}>
        {content}
        {overlay}
        </Suspense>
      </ExcelsiorErrorBoundary>
    );
}

function getComponentBasedOnState(stage) {
  switch(stage) {
    case HOME:
    case DETAILS:
    case LIBRARY:
    case PURCHASING:
    case READING:
      return <ExcelsiorReader/>
    default:
      return <div>Unknown stage</div>;
  }

  
}
