import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useSessionstorageState } from "rooks";

const BookDataContext = createContext();
const PDFRefContext = createContext();

const MediaMaxWidthSmallDevice = createContext();
const MediaMinWidth = createContext();
const MediaMaxWidth = createContext();

const ProvenanceShowContext = createContext();

const BookDataUpdateContext = createContext();
const PDFRefUpdateContext = createContext();

const ProvenanceShowUpdateContext = createContext();

//States
export function GetBookData() {
  return useContext(BookDataContext);
}
export function GetIssuePDFRef() {
  return useContext(PDFRefContext);
}

export function GetMediaMaxWidthSmallDevice() {
  return useContext(MediaMaxWidthSmallDevice);
}
export function GetMediaMinWidth() {
  return useContext(MediaMinWidth);
}
export function GetMediaMaxWidth() {
  return useContext(MediaMaxWidth);
}

export function GetProvenanceShow() {
  return useContext(ProvenanceShowContext);
}

// //Functions
export function SetBookData() {
  return useContext(BookDataUpdateContext);
}
export function SetIssuePDFRef() {
  return useContext(PDFRefUpdateContext);
}

export function SetProvenanceShow() {
  return useContext(ProvenanceShowUpdateContext);
}

const IssueDataProviderFunc = function ({ children }) {
  const [bookData, setBookData] = useState([null, null, null, null, false, null]);
  const [pdfRef, setPdfRef] = useState();

  const [provenance, setProvenance] = useState(false);

  const maxWidthSmallDevice = useMediaQuery("(max-width:800px)");
  const minWidth = useMediaQuery("(min-width:1200px)");
  const maxWidth = useMediaQuery("(max-width:1200px)");

  const SetBookData_func = useCallback(
    (val) => {
      setBookData(val);
    },
    []
  );


  const SetPDFRef_func = useCallback(
    (val) => {
      setPdfRef((pdfRef) => val);
    },

    []
  );

  const SetProvenanceShow_func = useCallback(
    (val) => {
      setProvenance((provenance) => val);
    },
  
    []
  );

  return useMemo(() => {
    return (
      <>
        <PDFRefContext.Provider value={pdfRef}>
          <PDFRefUpdateContext.Provider value={SetPDFRef_func}>
            <BookDataContext.Provider value={bookData}>
              <BookDataUpdateContext.Provider value={SetBookData_func}>
                <MediaMaxWidthSmallDevice.Provider value={maxWidthSmallDevice}>
                  <MediaMinWidth.Provider value={minWidth}>
                    <MediaMaxWidth.Provider value={maxWidth}>
                      <ProvenanceShowContext.Provider value={provenance}>
                        <ProvenanceShowUpdateContext.Provider
                          value={SetProvenanceShow_func}
                        >
                          {children}
                        </ProvenanceShowUpdateContext.Provider>
                      </ProvenanceShowContext.Provider>
                    </MediaMaxWidth.Provider>
                  </MediaMinWidth.Provider>
                </MediaMaxWidthSmallDevice.Provider>
              </BookDataUpdateContext.Provider>
            </BookDataContext.Provider>
          </PDFRefUpdateContext.Provider>
        </PDFRefContext.Provider>
      </>
    );
  }, [bookData, pdfRef, provenance, maxWidthSmallDevice, minWidth, maxWidth, SetPDFRef_func, SetBookData_func, SetProvenanceShow_func, children]);
}; 

export const IssueDataProvider = React.memo(IssueDataProviderFunc);
